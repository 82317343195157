<template>
  <div>
    <!-- <loader2 v-if="load == true"></loader2> -->
    <section class="breadcrumb-outer text-center bg-orange">
      <div class="container">
        <h2>{{ $t("Header.AboutUs.Manual") }}</h2>
      </div>
    </section>
    <section id="store-detail" class="store-detail">
      <div class="container">
        <div class="single-product-content">
          <div class="row" v-for="(manual, i) in manuals" :key="i">
            <div class="col-md-4 col-sm-12">
              <img
                :src="'https://backend.scocenter.uz/storage/' + manual.image"
                style="border-radius: 30px"
                alt="img"
                width="100%"
              />
            </div>
            <div class="col-md-8 col-sm-12">
              <div class="single-product-summary">
                <div class="entry-summary">
                  <h2 class="single-product-title">
                    {{ manual[`fname_${$i18n.locale}`] }}
                  </h2>
                  <!-- store tab -->
                  <div id="store-tabs" class="store-tabs">
                    <div class="row">
                      <div class="col-xs-12">
                        <div id="store-tab-main" class="">
                          <ul class="nav nav-tabs">
                            <li class="active">
                              <a href="#1" data-toggle="tab"
                                >{{ $t("tug") }}:</a
                              >
                            </li>
                            <li>
                              <a href="#2" data-toggle="tab"
                                >{{ $t("stu") }}:</a
                              >
                            </li>
                            <li>
                              <a href="#3" data-toggle="tab"
                                >{{ $t("car") }}:</a
                              >
                            </li>
                          </ul>

                          <div class="tab-content">
                            <div class="tab-pane active" id="1">
                              <p>{{ manual[`birthday_${$i18n.locale}`] }}</p>
                              <p v-if="$i18n.locale == 'cyril'">
                                {{ manual.birthday_kiril }}
                              </p>
                            </div>

                            <div class="tab-pane" id="2">
                              <p>{{ manual[`education_${$i18n.locale}`] }}</p>
                              <p v-if="$i18n.locale == 'cyril'">
                                {{ manual.education_kiril }}
                              </p>
                            </div>

                            <div class="tab-pane" id="3">
                              <p v-html="manual[`work_ex_${$i18n.locale}`]"></p>
                              <p
                                v-if="$i18n.locale == 'cyril'"
                                v-html="manual.work_ex_kiril"
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End store tab -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/End store detail -->
  </div>
</template>
<script>
import api from "../apis";
export default {
  name: "Manual",
  metaInfo: {
    title: "Boshqaruv",
    titleTemplate: "Boshqaruv",
  },
  data() {
    return {
      manuals: {},
      load: true,
      users: [],
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api.get("/manage").then((response) => {
          setTimeout(() => (this.load = false), 500);
          this.manuals = response.data.man;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
.bg-orange {
  margin: 0;
  background-color: #3498db !important;
  background-image: none;
}
.breadcrumb-outer {
  padding: 40px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 70px 0 10px !important;
}
.d-flex {
  display: flex;
  flex-direction: column;
}
#store-tabs {
  background: #fff;
  padding: 30px 0 0;
}

#store-tab-main .nav-tabs {
  border-color: #f1f1f1;
}

#store-tab-main .nav-tabs > li {
  margin: 0;
}

#store-tab-main .nav-tabs > li > a {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 12px 17px 12px;
  font-size: 18px;
  text-align: center;
  font-weight: 300;
  color: #6d77aa;
  background: transparent;
  border-radius: 0;
  margin: 0;
  line-height: inherit;
  border: none;
}

#store-tab-main .nav-tabs > li > a:hover,
#store-tab-main .nav-tabs > li.active > a,
#store-tab-main .nav-tabs > li.active > a,
#store-tab-main .nav-tabs > li.active > a:focus {
  color: #fff !important;
  border: none;
  background: #6d77aa;
}

#store-tab-main .tab-content {
  border: 1px solid #f1f1f1;
  padding: 30px;
  border-top: 1px solid transparent;
}

#store-tab-main .tab-content p {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .single-product-summary {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .entry-summary {
    margin-top: 15px;
  }
}
.our_store .slick-prev,
.our_store .slick-next {
  top: -46px;
  border: 1px solid #ccc;
  background: #fff;
  text-align: center;
  height: 35px;
  width: 35px;
  margin-top: 0;
  line-height: inherit;
}
@media (max-width: 400px) {
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
</style>